import React, { useState, useEffect } from "react";
import { withRouter, Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { setStore, t } from "../../utils/utils";
import { keytomap } from "../../utils/mapping";
import { toast } from 'react-toastify';
import { getData } from "../../store/appStoreSlice";
import { useSelector } from "react-redux";
import Config from '../../config';
import _ from "lodash";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "calc(100vh - 61px);",
    justifyContent: "center",
  },
  title: {
    color: "#FFF",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "18px",
    margin: "12px",
    textAlign: "center",
  },
  img: {
    maxWidth: "100%",
  },
  terminal: {
      backgroundColor: "#00003c",
      borderRadius: "10px",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      zIndex: 1,
      transition: "all 0.2s",
  },
  user: {
    color:"#5f6fff",
  },
  hvaldimir: {
    color:"#00ff00",
  },
  yellow: {
    color:"#FFD050",
    paddingTop: "12px",
    paddingLeft: "25px",
    position: "relative",
  },
  yellowh: {
    color:"#FFD050",
  },
  blue: {
    color:"#5f6fff",
  },
  time: {
    color:"#fff",
    fontWeight: "bold",
    paddingBottom: "12px",
    paddingLeft: "25px",
    position: "relative",
  },
  text:{
    paddingLeft: "25px",
    position: "relative",
  },
  pad:{
    lineHeight: "3em",
  },
  kub:{
    color:"#FFD050",
    border: "1px solid #FFD050",
    width: "12px",
    height: "12px",
    position: "absolute",
    left: "0",
    top: "16px",
  },
  fullkub:{
    color:"#FFD050",
    border: "1px solid #FFD050",
    backgroundColor: "#FFD050",
    width: "12px",
    height: "12px",
    position: "absolute",
    left: "0",
    top: "16px",
  },
  yelline:{
    backgroundColor: "#FFD050",
    width: "1px",
    height: "100%",
    position: "absolute",
    left: "6px",
    top: "0",
  },

  padlefttitle:{
    paddingLeft: "25px",
    lineHeight: "3em",
  },
  forurl:{color: "#fff"},
  status:{
      width: "250px",
      minWidth: "250px",
      textAlign: "center",
    padding: "20px",
  },
  chatstatus:{
    fontSize: "14px",
    padding: "20px",
    overflowY: "auto",
  },
  term:{
    flexDirection: "column",
    padding: "24px",
    overflow: "auto",
  },
}));

const Roadmap = (props) => {
  const classes = useStyles();
  const tredots = <><span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span></>
  const { modal } = useSelector(getData);
  const navigate = useHistory();

  const [audio] = useState(new Audio("/music/The-Bog-of-Eternal-Stink.mp3"));

  useEffect(() => {
      try {
        audio.play();
      } catch (error) {
        audio.play();
      }
      
      audio.loop = true;
      return () => {
        audio.pause();
      };
  }, []);


  return (
    <section className={classes.root}>
        <div className={classes.terminal}>
            <div className="terminal-header">
                <div className="buttons">
                    <span className="red"></span>
                    <span className="yellow"></span>
                    <span className="green"></span>
                </div>
                <span className="terminal-title">hvaldimir@neural_network:~</span>
            </div>
            <div className={`bigterminal-body ${classes.term}`}>

<h1  className={classes.yellowh}>Road Map:</h1>

<p  className={classes.yellow}><div className={classes.fullkub}></div>Create Hvaldimir</p>
<p  className={classes.time}><div className={classes.yelline}></div>AI Agent</p>

<p  className={classes.text}><div className={classes.yelline}></div>Hvaldimir’s creation begins with designing an AI agent that integrates seamlessly with blockchain technology, specifically Solana. This involves developing a robust neural network capable of managing decentralized portfolios while learning and adapting to market conditions. From processing real-time data to making informed decisions, Hvaldimir is built as a highly efficient, intelligent system with a strong focus on decentralization, transparency, and community engagement.
</p>

<p  className={classes.yellow}><div className={classes.fullkub}></div>Create Hvaldimir</p>
<p  className={classes.time}><div className={classes.yelline}></div>Integrations</p>

<p className={classes.text}><div className={classes.yelline}></div>Hvaldimir evolves with additional features such as Twitter integration. This allows the AI to engage directly with users by sharing insights, updates, and personalized interactions in real time. Whether it’s tweeting portfolio performance, explaining market shifts, or engaging with community polls, this functionality makes Hvaldimir a public-facing agent that fosters transparency and keeps users informed.
</p>

<p  className={classes.yellow}><div className={classes.kub}></div>Monetizing Creativity: $HVAL for Advanced AI Tools</p>
<p  className={classes.time}><div className={classes.yelline}></div>Moetization</p>

<p className={classes.text}><div className={classes.yelline}></div>Hvaldimir unlocks creative potential by offering advanced features like image, video, and voice generation from text. While most tools remain free during training and optimization, premium capabilities will be available via $HVAL tokens. This creates a dual ecosystem: free access to train the AI while leveraging $HVAL for sophisticated content creation, ensuring a balance between user value and ecosystem growth.
</p>

<p  className={classes.yellow}><div className={classes.kub}></div>The Future of AI Autonomy and Coding Agents</p>
<p  className={classes.time}><div className={classes.yelline}></div>Autonomy</p>

<p className={classes.text}><div className={classes.yelline}></div>Soon, Hvaldimir will expand its intelligence by coding additional AI agents independently. These agents, customizable and task-specific, will allow users to delegate unique projects or needs. Accessing these advanced capabilities will require payment in $HVAL tokens, driving token utility while empowering users to create bespoke AI solutions.
</p>

<p  className={classes.yellow}><div className={classes.kub}></div>Future announcements</p>
<p  className={classes.time}><div className={classes.yelline}></div>Upcoming community driven announcements</p>

<p className={classes.text}><div className={classes.yelline}></div>The community will decide what is next for their favourite whale.
</p>

            </div>
        </div>


    </section>
  )
}

export default withRouter(Roadmap);
